import React, {useState, useEffect} from "react"
import cx from 'classnames';

import styles from './index.module.css';

import Logo from "../../images/lifelogo.svg";
import Button from '../Button';

const Animation = (props) => (
  <div className={cx([styles.animation, props.className, props.isHidden && 'is-hidden'])}>
    <div className={styles.animationContent} style={{transitionDelay: props.delay}}>
      {props.children}
    </div>
  </div>
);

const WelcomeModal = ({isOpen, onClickClose}) => {
  const [isHidden, changeHiddenState] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      changeHiddenState(false)
    }, 1200);
  });

  return (
    <div className={cx([styles.wrapper, !isOpen && "is-closed"])}>
      <div className={styles.body}>
        <Animation isHidden={isHidden} delay="0s">
          <Logo className={styles.logo} />
        </Animation>

        <Animation className={styles.shipWrapper} isHidden={isHidden} delay="0.1s">
          <img src="/images/scarletsails.png" alt="" className={styles.ship} />
        </Animation>

        <Animation isHidden={isHidden} delay="0.2s">
          <h1 className={styles.title}>Привет! Это гид-карта по</h1>
        </Animation>
        <Animation isHidden={isHidden} delay="0.3s" className={styles.titleWrapper}>
          <h1 className={styles.title}>празднику «Алые паруса – 2019»</h1>
        </Animation>
        <Animation className={styles.dateWrapper} isHidden={isHidden} delay="0.3s">
          <p className={cx([styles.date])}>23 июня</p>
        </Animation>

        <Animation isHidden={isHidden} delay="0.4s">
          <p className={cx([styles.text, styles.textDesktop])}>Здесь можно посмотреть расписание и локации</p>
          <p className={cx([styles.text, styles.textMobile])}>Здесь можно посмотреть расписание и локации праздника и узнать другую</p>
        </Animation>
        <Animation isHidden={isHidden} delay="0.5s">
          <p className={cx([styles.text, styles.textDesktop])}>праздника и узнать другую полезную информацию.</p>
          <p className={cx([styles.text, styles.textMobile])}>полезную информацию. А также кликать, </p>
        </Animation>
        <Animation isHidden={isHidden} delay="0.6s" className={styles.textWrapper}>
          <p className={cx([styles.text, styles.textDesktop])}>А также кликать, скроллить, приближать, строить маршрут, посмотреть видео и гигараму.</p>
          <p className={cx([styles.text, styles.textMobile])}>скроллить, приближать, строить маршрут, посмотреть видео и гигараму.</p>
        </Animation>

        <Animation isHidden={isHidden} delay="0.7s">
          <Button className={styles.button} onClick={onClickClose}>Перейти к карте</Button>
        </Animation>
      </div>
    </div>
  )
}

WelcomeModal.defaultProps = {
  isOpen: false,
  onClickClose: () => {}
}


export default WelcomeModal
