import React from "react"
import cx from "classnames"

import Button from "../Button"
import RouteIcon from "../../images/route.svg"
import PinIcon from "../../images/pin.svg"

import {makeRouteFromCurrentPosition} from '../../utils/router'

import styles from "./index.module.css"

const Event = props => {
  return (
    <div>
      {
        // (props.video || props.image) &&
        props.image &&
        <div className={styles.media}>

          {/*{*/}
          {/*  !!props.video &&*/}
          {/*  <iframe id="ytplayer" type="text/html" width="100%" height="200"*/}
          {/*          src={props.video}*/}
          {/*          frameBorder="0"/>*/}
          {/*}*/}

          {
            props.image && props.image
          }
        </div>
      }

      {
        props.title &&
          <div className={styles.title}>{props.title}</div>
      }

      {
        props.text &&
          <div dangerouslySetInnerHTML={{ __html: props.text }} />
      }

      <div className={styles.row}>

        {
          props.persons.map(p => (
            <div className={cx(styles.row, styles.person)}>
              <div className={styles.ava}>
                <img className={styles.avaImage} src={p.icon} alt=""/>
              </div>
              <div>
                <div className={styles.personName}>{p.name}</div>
                <div className={styles.personStatus}>{p.role}</div>
              </div>
            </div>
          ))
        }

        {/*<div className={cx(styles.row, styles.person)}>
          <div className={styles.ava}>
            ava
          </div>
          <div>
            <div className={styles.personName}>Иван Ургант</div>
            <div className={styles.personStatus}>status</div>
          </div>
        </div>

        <div className={cx(styles.row, styles.person)}>
          <div className={styles.ava}>
            ava
          </div>
          <div>
            <div className={styles.personName}>Дарья Александрова</div>
            <div className={styles.personStatus}>status</div>
          </div>
        </div>*/}
      </div>

      {
        props.artists &&
        <div style={{ marginTop: '25px' }}>
          <div className={styles.personName}>Артисты:</div>
          <div style={{ marginTop: '5px' }}>
            {props.artists.join(', ')}
          </div>
        </div>
      }

      {
        props.address &&
          <div className={styles.text}>
            <PinIcon/> {props.address}
          </div>
      }

      {
        props.content
      }

      <div style={{ marginTop: '10px' }}>
        <Button onClick={() => {props.onClickRoute(props.coords)}} theme={"dark"} prefix={<RouteIcon/>}>
          Проложить маршрут
        </Button>
      </div>
    </div>
  )
}

Event.propTypes = {}

Event.defaultProps = {
  persons: [],
  address: "",
}

export default Event
