import React from "react"
import cx from 'classnames';

import styles from './index.module.css';

import CloseButton from "../CloseButton"


const IframeModal = ({isOpen, isFullScreen, onClickClose, src}) => {
  return (
    <div className={cx([styles.wrapper, !isOpen && "is-closed"])}>
      <div className={cx([styles.body, isFullScreen && styles.isFullScreen])}>
        <div className={styles.playerWrapper}>
          {isOpen && <iframe src={src} className={styles.player}></iframe>}
        </div>
      </div>

      <CloseButton onClickClose={onClickClose} className={styles.closeButton}/>
    </div>
  )
}

IframeModal.defaultProps = {
  isOpen: false,
  isFullScreen: false,
  src: '',
  onClickClose: () => {}
}


export default IframeModal
