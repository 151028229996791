import React from "react"
import PropTypes from "prop-types"
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion"

import styles from './index.module.css';
// import 'react-accessible-accordion/dist/fancy-example.css';

const Accord = props => {
  return (
    <Accordion allowZeroExpanded>

      {
        props.items.map(item => (
          <AccordionItem className={styles.wrapper}>

            {/**/}

            <AccordionItemHeading>
              <AccordionItemButton className={styles.head}>
                <div className={styles.icon}>
                  {item.icon}
                </div>
                <div className={styles.headRow}>
                  <div className={styles.title}>{item.title}</div>
                  <div className={styles.description}>{item.description}</div>
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>

            {/**/}

            <AccordionItemPanel className={styles.content}>
              {
                item.content
              }
            </AccordionItemPanel>
          </AccordionItem>
        ))
      }
    </Accordion>
  )
}

Accord.defaultProps = {
  items: []
}
Accord.propTypes = {

}

export default Accord
