var state = {
  route: null
}

export const showCurrentPosition = async (ymaps, map) => {
  ymaps = ymaps || window.ymaps;
  map = map || window.yaMap;

  const res = await ymaps.geolocation.get({
    mapStateAutoApply: true
  });

  map.geoObjects.add(res.geoObjects);
}

export const getCurrentPosition = async (ymaps) => {
  const res = await ymaps.geolocation.get({
    mapStateAutoApply: true
  });

  return res.geoObjects.position;
}

export const makeRouteFromCurrentPosition = async (target, ymaps, map) => {
  ymaps = ymaps || window.ymaps;
  map = map || window.yaMap;

  try {
    const current = await getCurrentPosition(ymaps);
    const targetcoords = Array.isArray(target) ? target : target.split(', ');

    await makeRoute(ymaps, map, current, targetcoords);
  } catch (e) {
    console.log('ERROR', e);
    return false;
  }

}

export const makeRoute = (ymaps, map, start, end) => {
  ymaps.route([start, end]).then(route => {
    route.getPaths().options.set('strokeColor', '#FA0A0A');

    if (state.route) {
      map.geoObjects.remove(state.route);
    }

    var points = route.getWayPoints();
    for (let i = 0; i < points.getLength(); ++i) {
      points.get(i).events.add('click', (e) => {
        e.stopPropagation();
        e.preventDefault();
        return false;
      })
    }

    state.route = route;
    map.geoObjects.add(route);
  })
}

export const clearRoute = (map) => {
  map = map || window.yaMap;

  if (state.route) {
    map.geoObjects.remove(state.route);
    state.route = null;
  }
}

