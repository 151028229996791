import React from "react"
import PropTypes from "prop-types"
import cx from 'classnames';

import styles from './index.module.css';

const Button = props => {
  const rootClassName = cx(
    styles.wrapper,
    styles[props.theme],
    props.className || false
  );

  return (
    <button className={rootClassName} onClick={props.onClick}>
      {
        props.prefix &&
        <div className={styles.prefix}>{props.prefix}</div>
      }
      {props.children}
    </button>
  )
}

Button.defaultProps = {
  onClick: () => {},
  theme: 'default'
}

export default Button
