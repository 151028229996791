import React, { useState } from "react"
import PropTypes from "prop-types"
import cx from "classnames"

import styles from "./index.module.css"

import CloseButton from "../CloseButton"

import ClockSvg from "../../images/clock.svg"
import Deg360Svg from "../../images/deg360.svg"
import DownloadSvg from "../../images/download.svg"

const PANO_TYPE = "panorama"

const timeline = [{
  time: "20:00",
  content: "<p><b>Сбор выпускников на Дворцовой площади. Выступление молодёжных коллективов</b></p>",
}, {
  time: "22:00",
  content: `
    <p><b>Концерт на Дворцовой площади</b></p>
    <p><b>Ведущие</b> <br/> Иван Ургант и Дарья Александрова</p>
    <p><b>Артисты</b> <br/> Маркус Рива #2МАШИ Зара Lera Владимир Пресняков BURITO Юля Паршута группа "Марсель" Сергей Лазарев Полина Гагарина</p>
    `,
}, {
  time: "00:40",
  content: "<p><b>Пиротехническое шоу и появление брига с алыми парусами</b></p>",
}, {
  time: "01:05–03:00",
  content: "<b>Продолжение концерта на Дворцовой площади. Выступление молодёжных коллективов</b>",
}]

const infoblocks = [
  [{
    title: "Размещение выпускников",
    description: "Для размещения выпускников отводится специальная площадка, ограниченная Дворцовой площадью и Дворцовой набережной от Дворцового до Троицкого моста. Вход на Дворцовую площадь осуществляется только по пригласительным билетам.",
  }, {
    title: "Как пройти на Дворцовую площадь",
    description: "Проход на Дворцовую площадь будет осуществляться через 5 пропускных пунктов: Певческий мост, Большая Морская улица, Адмиралтейский проспект, Миллионная улица, Невский проспект.",
  }, {
    title: "Работа метрополитена",
    description: "В ночь проведения праздника выпускников \"Алые паруса\" метрополитен работает круглосуточно. Адмиралтейская и некоторые другие станции могут временно закрываться на вход из-за большого потока людей.",
  }, {
    title: "Медицинская помощь",
    description: "Точки, где дежурят врачи указаны на карте. Кроме бригад скорой помощи, которые будут дежурить на празднике, первую медицинскую помощь смогут оказать Волонтёры Красного Креста."
  }],
  [{
    title: "Панорама 360",
    // description: "Во время праздника на Дворцовой площади мы снимем гигараму. Это фото 360° настолько высокого разрешения, что при увеличении можно будет разглядеть лица всех выпускников на концерте.",
    description: "",
    type: PANO_TYPE,
  }, {
    title: "Где посмотреть бесплатно?",
    description: "Все желающие смогут посмотреть на грандиозное пиротехническое шоу с Благовещенского, Тучкова и Литейного мостов, с Английской, Адмиралтейской, Университетской и Петровской набережных и с Дворцовой набережной у Летнего сада.",
  }, {
    title: "Что нельзя брать с собой",
    description: "Обращаем внимание, что пронос воды, алкоголя и других жидкостей, веществ, запрещённых законодательством РФ, а также острых режущих предметов и оружия на территорию проведения праздника строго воспрещён. На входе разместят металлоискатели.",
    link: {
      text: "Полный список запрещенных веществ.doc",
      url: "/docs/prohibited.pdf",
    },
  }, {
    title: "Ограничение проезда",
    description: "Во время подготовки и проведения праздника будет ограничено или изменено движение по некоторым улицам в центре города. Поэтому не рекомендуем приезжать на праздник на машине. <br/> <b>Важно</b>: для обеспечения безопасности зрителей в схему организации праздника могут вноситься изменения. Просим отнестись к этому с пониманием.",
    link: {
      text: "Подробнее о перекрытии дорог",
      url: "http://gudodd.ru/2019/06/11/prazdnik-alye-parusa/",
    },
  }]
];

const SpecialPartInfoBlock = (block) => {
  if (block.link) {
    return (
      <a className={styles.blockLink} href={block.link.url} target="_blank"><DownloadSvg/>
        <span>{block.link.text}</span></a>
    )
  }

  return null
}

const InfoWindow = ({ isOpen, onClickClose, onClickOpen360 }) => {
  return (
    <div className={cx([styles.wrapper, isOpen && "is-open"])}>
      <section className={styles.body}>
        <h1 className={styles.heading}>Информация о мероприятии</h1>
        <section className={styles.content}>
          {/* Таймлайн */}
          <div className={styles.contentLeft}>
            {timeline.map(timeObject => (
              <section className={styles.timeBlock}>
                <h3 className={styles.timeBlockTitle}><ClockSvg className={styles.timeBlockIcon}/>{timeObject.time}</h3>
                <div className={styles.timeBlockDescription}
                     dangerouslySetInnerHTML={{ __html: timeObject.content }}></div>
              </section>
            ))}
          </div>

          {/* Основное описание */}
          <div className={styles.contentRight}>
            {infoblocks.map(column => (
              <div className={styles.contentRightColumn}>
                {column.map( infoBlock => (
                  <section className={styles.infoBlock}>
                    <h3 className={styles.infoBlockTitle}>{infoBlock.title}</h3>
                    <p className={styles.infoBlockDescription}
                       dangerouslySetInnerHTML={{ __html: infoBlock.description }}></p>
                    <SpecialPartInfoBlock {...infoBlock} />
                    {infoBlock.type && infoBlock.type === PANO_TYPE && (
                      <div onClick={onClickOpen360} className={styles.deg360}><Deg360Svg/> <span>Во время праздника на Дворцовой площади мы сняли гигараму. Это фото 360° настолько высокого разрешения, что при увеличении можно разглядеть лица всех выпускников. Найдете себя?</span></div>
                    )}
                  </section>
                ))}
              </div>

            ))}
          </div>
        </section>
      </section>

      <CloseButton onClickClose={onClickClose} className={styles.closeButton}/>
    </div>
  )
}

InfoWindow.defaultProps = {
  isOpen: false,
  onClickClose: () => {},
}

export default InfoWindow
