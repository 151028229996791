import React from "react"
import PropTypes from "prop-types"
import cx from 'classnames';

import MenuIcon from '../../images/menu.svg';
import EnterIcon from '../images/enter';
import MicroIcon from '../images/micro';
import WCIcon from '../images/wc';

import Button from '../Button';

import styles from './index.module.css';

const ListMenu = props => {
  return (
    <div className={cx(styles.wrapper, {[styles.open]: props.open})}>
      <div className={styles.blockTitle}>Навигация по карте:</div>

      <div className={styles.itemsWrapper}>
        <div className={styles.items}>
          {
            props.items.map(item => (
              <div className={styles.item} onClick={() => props.onClickItem(item.type)}>
                <div className={styles.icon}>{item.icon}</div>
                <div className={styles.row}>
                  <div className={styles.title}>{item.title}</div>
                  <div className={styles.description}>{item.description}</div>
                </div>

                <div className={styles.count}>{item.count}</div>
              </div>
            ))
          }

          <div className={styles.viewAll} onClick={() => props.changeOpen(!props.open)}>
            { props.open
              ? '< Свернуть список'
              : null }
          </div>
        </div>

      </div>
      <div className={styles.viewAll} onClick={() => props.changeOpen(!props.open)}>
        { props.open
          ? null
          : <> <MenuIcon/> Посмотреть весь список </> }
      </div>


    </div>
  )
}

ListMenu.propTypes = {
  items: PropTypes.array,
  open: PropTypes.bool,
  changeOpen: PropTypes.func
}

ListMenu.defaultProps = {
  items: [
    {
      icon: <EnterIcon/>,
      title: 'Входы/Выходы',
      description: 'Открыты с 21:00',
      count: '3'
    },
    {
      icon: <MicroIcon/>,
      title: 'Сцена',
      description: 'Начало концерта в 22:30',
      count: '1'
    },
    {
      icon: <WCIcon/>,
      title: 'WC',
      description: 'Бесплатные',
      count: '8'
    },
    {
      icon: <WCIcon/>,
      title: 'WC',
      description: 'Бесплатные',
      count: '8'
    },
    {
      icon: <WCIcon/>,
      title: 'WC',
      description: 'Бесплатные',
      count: '8'
    },
    {
      icon: <WCIcon/>,
      title: 'WC',
      description: 'Бесплатные',
      count: '8'
    },
    {
      icon: <WCIcon/>,
      title: 'WC',
      description: 'Бесплатные',
      count: '8'
    },
    {
      icon: <WCIcon/>,
      title: 'WC',
      description: 'Бесплатные',
      count: '8'
    },
    {
      icon: <WCIcon/>,
      title: 'WC',
      description: 'Бесплатные',
      count: '8'
    },
    {
      icon: <WCIcon/>,
      title: 'WC',
      description: 'Бесплатные',
      count: '8'
    },
    {
      icon: <WCIcon/>,
      title: 'WC',
      description: 'Бесплатные',
      count: '8'
    },
    {
      icon: <WCIcon/>,
      title: 'WC',
      description: 'Бесплатные',
      count: '8'
    },
    {
      icon: <WCIcon/>,
      title: 'WC',
      description: 'Бесплатные',
      count: '8'
    }
  ]
}

export default ListMenu
