/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from "gatsby"
import Select, { components } from "react-select";

import Button from './Button';
import Sidebar from "./sidebar"
import InfoWindow from './InfoWindow'
import IframeModal from './IframeModal'
import WelcomeModal from './WelcomeModal'
import MobileHeader from './MobileHeader'
import Map from './Map/map';
import styles from './layout.module.css'
import "./layout.css"

import { points, zones, polylines, objectTypes } from "../data"
import {clearRoute, makeRouteFromCurrentPosition} from '../utils/router'

const options = [
  ...points.map(el => ({ value: el.coords, label: `${el.address} ${el.balloon ? el.balloon.title:''} ${el.balloon ? el.balloon.address: ''}`, ...el })),
  // ...zones.map(el => ({ value: el.coords, label: `${el.address} ${el.balloon ? el.balloon.title:''} ${el.balloon ? el.balloon.address: ''}`, ...el })),
  // ...polylines.map(el => ({ value: el.coords, label: `${el.address} ${el.balloon ? el.balloon.title:''} ${el.balloon ? el.balloon.address: ''}`, ...el }))
];

// const options = [
//   ...points,
//   ...zones,
//   ...polylines,
// ];

const icons = (type) => {
  switch (type) {
    case objectTypes.toilet:
      return "/images/wc.png"
    case objectTypes.metro:
      return "/images/metro.png"
    case objectTypes.fireshow:
      return '/images/firework.png'
    case objectTypes.ship:
      return '/images/scarletsails.png'
    case objectTypes.scene:
      return '/images/scene.png'
    case objectTypes.ambulance:
      return '/images/medecine.png'
    case objectTypes.info:
      return '/images/info.png'
    case objectTypes.gate:
      return '/images/enter.png'
    case objectTypes.eat:
      return '/images/food.png'
    case objectTypes.specialPersons:
      return '/images/invalid.png'
    default:
      return ""
  }
}

const SingleValue = ({ children, ...props }) => {
  return (
    <components.SingleValue {...props}>
      {props.data.title && props.data.title + ", "}{props.data.address}
    </components.SingleValue>
  )
}
const Option = ({ children, ...props }) => {
  return (
    <components.Option {...props} >
      <div style={{ minHeight: 44, display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '5px 10px' }}>
        <img style={{ width: 20, height: 'auto', margin: 'auto 5px auto 0' }} src={icons(props.data.type)} alt=""/>
        <div>
          {props.data.title && <b>{props.data.title+', '}</b>}{props.data.address}
        </div>
      </div>
    </components.Option>
  )
}



const customStyles = {
  control: (base) => ({
    // none of react-select's styles are passed to <Control />
    ...base,
    width: 250,
    height: 44,
    border: 'none',
    borderRadius: '0'
  }),
  option: (b) => ({
    ...b,
    padding: 0
  })
}

let noBounceInited = false;

const Layout = ({ children }) => {
  const [isInfoOpen, changeInfoOpen] = useState(false);
  const [isWelcomeOpen, changeWelcomeOpen] = useState(true);
  const [type, changeType] = useState('all');
  const [is360ModalOpen, change360ModalOpenState] = useState(false);
  const [isBrigModalOpen, changeBrigModalOpenState] = useState(false);
  const [route, changeRouteState] = useState(null);

  const close360Modal = () => change360ModalOpenState(false);
  const open360Modal = () => {
    window.ym('54110977', 'reachGoal', 'panorama');
    change360ModalOpenState(true);
  }
  const closeBrigModal = () => changeBrigModalOpenState(false);
  const openBrigModal = () => changeBrigModalOpenState(true);

  const toggleInfoOpen = () => {
    changeInfoOpen(!isInfoOpen);
  }

  const toggleWelcomeOpen = () => {
    document.body.style.overflow = 'auto';
    changeWelcomeOpen(!isWelcomeOpen);
  }

  const resetAll = () => {
    changeType('all');
    changeRouteState(null);
    clearRoute();
  }

  const makeRoute = (coords) => {
    makeRouteFromCurrentPosition(coords).then(route => {
      changeRouteState(true);
    });
  }

  useEffect(() => {
    // if (window.noBounce && !noBounceInited && !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) {
    //   noBounceInited = true;
    //   window.noBounce.init({
    //     animate: true
    //   });
    // }
  });

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <div style={{ display: 'flex', height: '100%' }} className={styles.content}>
          <Helmet>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/hammer.js/2.0.8/hammer.min.js"/>
          </Helmet>

          <Sidebar
            siteTitle={data.site.siteMetadata.title}
            onClickInfo={toggleInfoOpen}
            type={type}
            changeType={changeType}
            onClickOpen360={open360Modal}
            onClickOpenBrig={openBrigModal}
            makeRoute={makeRoute}
          />
          <div className={styles.wrapper}>
            {children}
            <Map
              type={type}
              resetAll={resetAll}
              hasReset={route || type !== 'all'}
              makeRoute={makeRoute}
            />

            {/* Глобальыне модалки*/}
            {is360ModalOpen && (<IframeModal isFullScreen={true} src="https://gigarama.ru/parusa2019/" isOpen={is360ModalOpen} onClickClose={close360Modal}/>)}
            <IframeModal src="https://www.youtube.com/embed/wTsZmClmaks" isOpen={isBrigModalOpen} onClickClose={closeBrigModal}/>
            <InfoWindow isOpen={isInfoOpen} onClickClose={toggleInfoOpen} onClickOpen360={open360Modal}/>

            <div className={styles.search} /*style={{ left: document.getElementsByTagName('header')[0].clientWidth + 30 }}*/>
              <Select
                isSearchable
                styles={customStyles}
                className={styles.search__input}
                placeholder={'Поиск по карте'}

                components={{ DropdownIndicator: null, SingleValue, Option }}

                options={options}

                onChange={({value})=>{
                  changeType(value);
                  window.yaMap.setCenter(value.split(', '));
                }}
              />
              <div></div>
              <Button>Найти</Button>
            </div>
          </div>

          <WelcomeModal isOpen={isWelcomeOpen} onClickClose={toggleWelcomeOpen}/>
          <MobileHeader onClickOpenAbout={toggleInfoOpen} onClickOpenBrig={openBrigModal} />
        </div>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
