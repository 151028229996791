import React from "react"
import cx from 'classnames';

import styles from "./index.module.css"


const CloseButton = ({onClickClose, className}) => {

  return (
    <span className={cx([styles.closeButton, className])} onClick={onClickClose}>
        Закрыть&nbsp;&nbsp;
      <span className={styles.closeButtonCross}>✕</span>
    </span>
  )
}


export default CloseButton;
