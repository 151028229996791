import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"

import cx from "classnames"

import { points, objectTypes, zones, polylines } from "../data"

import Logo from "../images/lifelogo.svg"
import InfoIcon from "../images/info.svg"
import ArrowIcon from "../images/arrowdown.svg"
import Icon360 from "../images/deg360.svg"
import PlayIcon from "../images/play.svg"
import Button from "./Button"
import Accordion from "./Accordion"
import Event from "./Event"
import ListMenu from "./ListMenu"

import Microphone from "./images/microphone"
import Sailboat from "./images/sailboat"
import Fireworks from "./images/fireworks"
import FireshowPic from "./images/fireshowPic"
import ScarletSailPic from "./images/scarletSailPic"
import ConcertPic from "./images/concertPic"

import MicroIcon from "./images/micro"
import WCIcon from "./images/wc"
import MetroIcon from "./images/metro"
import AmbulanceIcon from "./images/ambulance"
import EatIcon from "./images/eat"
import EnterIcon from "./images/enter"
import InfoSvg from "../images/info.svg"

import styles from "./sidebar.module.css"

import Select, { components } from "react-select"

const options = [
  ...points.map(el => ({
    value: el.coords,
    label: `${el.address} ${el.balloon ? el.balloon.title : ""} ${el.balloon ? el.balloon.address : ""}`, ...el,
  })),
  // ...zones.map(el => ({
  //   value: el.coords,
  //   label: `${el.address} ${el.balloon ? el.balloon.title : ""} ${el.balloon ? el.balloon.address : ""}`, ...el,
  // })),
  // ...polylines.map(el => ({
  //   value: el.coords,
  //   label: `${el.address} ${el.balloon ? el.balloon.title : ""} ${el.balloon ? el.balloon.address : ""}`, ...el,
  // })),
]
const SingleValue = ({ children, ...props }) => {
  return (
    <components.SingleValue {...props}>
      {props.data.title && props.data.title + ", "}{props.data.address}
    </components.SingleValue>
  )
}
const Option = ({ children, ...props }) => {
  return (
    <components.Option {...props} >
      <div style={{ minHeight: 44, display: "flex", flexDirection: "row", alignItems: "center", padding: "5px 10px" }}>
        <img style={{ width: 20, height: "auto", margin: "auto 5px auto 0" }} src={icons(props.data.type)} alt=""/>
        <div>
          {props.data.title && <b>{props.data.title + ", "}</b>}{props.data.address}
        </div>
      </div>
    </components.Option>
  )
}
const customStyles = {
  container: (b) => ({
    ...b,
    width: "100%",
  }),
  control: (base) => ({
    // none of react-select's styles are passed to <Control />
    ...base,
    width: "100%",
    height: 44,
    border: "none",
    boxShadow: "none",
    borderRadius: "0",
    background: "#F5F5F7",
  }),
  option: (b) => ({
    ...b,
    padding: 0,
  }),
  input: (b) => ({
    // ...b,
    height: 44,
    width: "100%",
  }),
}
const icons = (type) => {
  switch (type) {
    case objectTypes.toilet:
      return "/images/wc.png"
    case objectTypes.metro:
      return "/images/metro.png"
    case objectTypes.fireshow:
      return "/images/firework.png"
    case objectTypes.ship:
      return "/images/scarletsails.png"
    case objectTypes.scene:
      return "/images/scene.png"
    case objectTypes.ambulance:
      return "/images/medecine.png"
    case objectTypes.info:
      return "/images/info.png"
    case objectTypes.gate:
      return "/images/enter.png"
    case objectTypes.eat:
      return "/images/food.png"
    default:
      return ""
  }
}

// let Hammer = null;
// try {
//   Hammer = require('hammerjs');
// } catch (e) {
let Hammer = null
try {
  Hammer = require("hammerjs")
} catch (e) {

}

const getItems = ({onClickRoute}) => [
  {
    icon: <Microphone/>,
    title: "Концерт на Дворцовой площади",
    description: "Начало в 22:00",
    content: <Event
      coords="59.939232, 30.315660"
      onClickRoute={onClickRoute}
      image={<ConcertPic/>}
      video={"http://www.youtube.com/embed/M7lc1UVf-VE?autoplay=1&origin=http://example.com"}
      text={"<div>" +
      "<b>Программа праздника \"Алые Паруса\":</b><br/><br/>" +
      "<b>20:00</b> — сбор выпускников на Дворцовой площади. Выступление молодёжных коллективов<br/><br/>" +
      "<b>22:00</b> — концерт на Дворцовой площади<br/><br/>" +
      "<b>00:40</b> — пиротехническое шоу и появление брига с алыми парусами<br/><br/>" +
      "<b>01:05–03:00</b> — продолжение концерта на Дворцовой площади. Выступление молодёжных коллективов<br/><br/><br/>" +
      "</div>"
      }
      persons={[
        {
          name: "Иван Ургант",
          role: "Ведущий",
          icon: "/images/man.jpg",
        },
        {
          name: "Дарья Александрова",
          role: "Ведущая",
          icon: "/images/woman.jpg",
        }]}
      artists={["Маркус Рива", "#2МАШИ", "Зара", "Lera", "Владимир Пресняков",
        "BURITO", "Юля Паршута", "группа \"Марсель\"", "Сергей Лазарев", "Полина Гагарина"]}
    />,
  },
  {
    icon: <Sailboat/>,
    title: "Проход парусника «Россия»",
    description: "00:40–1:05",
    content: <Event
      coords="59.945226, 30.325354"
      onClickRoute={onClickRoute}
      image={<ScarletSailPic/>}
      title={"Проход парусника \n" +
      "«Россия» в 00:40–1:05"}
      address={"Акватория Невы"}
      // content={<div style={{ width: "219px", height: "44px", margin: "0 0 20px 0" }}></div>}
    />,
  },
  {
    icon: <Fireworks/>,
    title: "Пиротехническое шоу ",
    description: "00:40–1:05",
    content: <Event
      coords="59.943986, 30.320881"
      onClickRoute={onClickRoute}
      image={<FireshowPic/>}
      title={"Пиротехническое шоу\n" +
      "в 00:40–1:05"}
      address={"Акватория Невы у Петропавловской крепости"}
      // content={<div style={{ width: "219px", height: "44px", margin: "0 0 20px 0" }}><Button>Смотреть
      //   трансляцию</Button></div>}
    />,
  },
]

const Sidebar = ({ makeRoute, siteTitle, onClickInfo, changeType, type, onClickOpen360, onClickOpenBrig }) => {
  const [open, changeOpen] = useState(false)
  const [isMenuOpen, changeMenuOpen] = useState(false)

  const toggleOpen = () => {
    if (window.innerWidth < 480) {
      changeOpen(!open)
    }
  }

  const onChangeMenuOpen = (state) => {
    changeMenuOpen(state);

    if (elWrapper && elWrapper.current) {
      elWrapper.current.scrollTop = 0;
    }
  }

  const el = useRef(null);
  const elWrapper = useRef(null);

  useEffect(() => {
    const hummer = new Hammer(el.current)

    hummer.get("pan").set({ direction: Hammer.DIRECTION_ALL })

    hummer.on("panup", function(ev) {
      changeOpen(true)
    })
    hummer.on("pandown", function(ev) {
      changeOpen(false)
    })
  }, [])

  const listItems = []
  Object.values(objectTypes).forEach(key => {
    switch (key) {
      case objectTypes.toilet:
        listItems.push({
          title: "WC, санитарная зона с бесплатными туалетами",
          description: "",
          icon: <WCIcon/>,
          count: points.find(el => el.type === key).count,
          type: key,
        })
        break
      case objectTypes.metro:
        listItems.push({
          title: "Станция метро",
          count: points.find(el => el.type === key).count,
          type: key,
          icon: <MetroIcon/>,
        })
        break
      case objectTypes.ambulance:
        listItems.push({
          title: "Скорая помощь",
          count: points.find(el => el.type === key).count,
          type: key,
          icon: <AmbulanceIcon/>,
        })
        break
      case objectTypes.eat:
        listItems.push({
          title: "Фудкорт",
          count: points.find(el => el.type === key).count || 1,
          type: key,
          icon: <EatIcon/>,
        })
        break
      case objectTypes.gate:
        listItems.push({
          title: "Вход/Выход",
          count: points.find(el => el.type === key).count || 1,
          type: key,
          icon: <EnterIcon/>,
        })
        break
      default:
        break
    }
  })

  return (
    <>
      <div className={cx(styles.button, { [styles.open]: open })}
           onClick={() => changeOpen(!open)}>{open ? "<" : ">"}</div>

      <header className={cx(styles.wrapper, { [styles.open]: open })} ref={elWrapper}>
        <div className={styles.head}>
          <a href="https://life.ru" target="_blank" onClick={() => window.ym('54110977', 'reachGoal', 'life')}><Logo/></a>
          <h3 className={styles.infoHeading} onClick={onClickInfo}>О мероприятии <InfoSvg
            className={styles.infoHeadingIcon}/></h3>
          <div onClick={() => changeOpen(false)} className={styles.close}>
            ✕
          </div>
        </div>

        <div className={styles.mobileHead}>
          <div className={styles.mobileHeadTouch} ref={el}></div>
          <div className={styles.mobileHeadBody}>
            <div className={styles.icon} onClick={toggleOpen}>
              <ArrowIcon className={cx([styles.arrowIcon, open && "is-open"])}/>
            </div>

            <Select
              isSearchable
              styles={customStyles}
              // className={styles.search__input}
              placeholder={"Поиск по карте"}
              components={{
                DropdownIndicator: null,
                SingleValue,
                Option,
              }} //Input: ({ children, ...props }) => <input {...props} type="text" onFocus={() => {changeOpen(true); props.onFocus()}}/>
              options={options}
              onFocus={() => changeOpen(true)}
              onChange={(e) => {
                changeType(e.value);
                changeOpen(false);
                try {
                  window.yaMap.setCenter(e.value.split(', '));
                } catch(e) {
                  console.log(e);
                }
              }}
            />
          </div>
        </div>
        <div className={styles.content}>
          <div className={cx(styles.info, { [styles.open]: isMenuOpen })}>
            <div className={styles.title}>
              Интерактивная <br/> карта мероприятия
            </div>
            <div className={styles.description}>
              Синим на карте обозначена зона, куда можно пройти только с пригласительным билетом. Зелёная зона доступна всем желающим. Серая зона - территория закрыта.
            </div>
            <div className={styles.row}>
              <span onClick={onClickOpenBrig} style={{ width: 170, textDecoration: "none" }}>
                <Button prefix={<PlayIcon style={{ fill: "white" }}/>}>
                  Увидеть бриг
                </Button>
              </span>

              <span onClick={onClickOpen360} style={{ width: 170, textDecoration: "none" }}>
                <Button prefix={<Icon360/>} theme={"empty"}>
                  Панорама 2019
                </Button>
              </span>
            </div>
          </div>

          <div className={cx(styles.block, { [styles.open]: isMenuOpen })}>
            <div className={styles.blockTitle}>Расписание:</div>
            <Accordion
              items={getItems({
                onClickRoute: (coords) => {
                  makeRoute(coords);
                  toggleOpen();
                }
              })}
            />
          </div>

          <div className={styles.em}/>
          <ListMenu items={listItems} open={isMenuOpen} changeOpen={onChangeMenuOpen} onClickItem={(t) => {
            if (type === t) {
              changeType("all");
            } else {
              changeType(t)
            }

            toggleOpen();
          }}/>
        </div>

        <div className={styles.footer}>
          <div style={{ marginTop: "auto" }}>
            © <a href="https://life.ru">Life</a>, 2019 г. Все права защищены
          </div>
        </div>
      </header>
    </>
  )
}

Sidebar.propTypes = {
  siteTitle: PropTypes.string,
}

Sidebar.defaultProps = {
  siteTitle: ``,
}

export default Sidebar
