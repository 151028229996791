import React from "react"
import cx from "classnames"

import styles from "./index.module.css"

import Logo from "../../images/lifelogo.svg"
import PlayIcon from "../../images/play.svg"
import InfoIcon from "../../images/info.svg"

const MobileHeader = ({ onClickOpenAbout, onClickOpenBrig }) => {
  return (
    <div className={styles.wrapper}>
      <a onClick={() => window.ym('54110977', 'reachGoal', 'life')}
         href="https://life.ru" target="_blank"
         className={styles.logo}>
        <Logo style={{height: '24px', width: "65px"}}/></a>

      <div className={styles.links}>
        <div className={styles.link} onClick={onClickOpenBrig}>
          <PlayIcon className={styles.playIcon}/> Увидеть бриг
        </div>
        <div className={styles.link} onClick={onClickOpenAbout}>
          <InfoIcon className={styles.infoIcon}/>
        </div>
      </div>
    </div>
  )
}

MobileHeader.defaultProps = {
  onClickOpenAbout: () => {},
  onClickOpenBrig: () => {},
}

export default MobileHeader
